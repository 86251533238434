module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#f0e6f6',

  socialColor: '#B6BCC1',

  primary: '#2193F1',
  primaryLight: '#45ffec',
  primaryDark: '#064bb8',

  secondary: '#212BF1',
  secondaryLight: '#ff79b0',
  secondaryDark: '#c60055',

  landingTextColor: '#ffffff'
};
